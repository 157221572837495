/*
 * @Author: '范彩霞' '14305118+fan-caixia@user.noreply.gitee.com'
 * @Date: 2024-06-13 13:58:51
 * @LastEditors: '范彩霞' '14305118+fan-caixia@user.noreply.gitee.com'
 * @LastEditTime: 2024-06-17 15:46:19
 * @FilePath: \hw_pc_managere:\projects\yhpc-PC\visualization\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import store from './store/index';
//6.引入导出的路由
import router from './router/index'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/common.css'/*引入公共样式*/
import VueClipBoard from 'vue-clipboard2'

import scroll from 'vue-seamless-scroll'

Vue.use(VueClipBoard)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$tianditu = window.tianditu
new Vue({
  //7.注册路由
  router,
  store,
  scroll,
  render: h => h(App),
}).$mount('#app')

