/*
 * @Author: xuwencheng 2113292693@qq.com
 * @Date: 2023-12-04 10:30:42
 * @LastEditors: '李敏' '478428011@qq.com'
 * @LastEditTime: 2024-09-09 15:37:16
 * @FilePath: \hw_pc_manager\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//1.导入VueRouter
import Vue from "vue";
import VueRouter from "vue-router";

//2.使用路由
Vue.use(VueRouter);
//3.创建VueRouter的实例
const router = new VueRouter({
  //tips:不想要 #（锚点）就添加下面代码
  mode: "hash",
  //  base: '/admin/', // 添加前缀路径
  //4.配置路由的path和组件
  routes: [

    {

      path: "/",
      name: "layout",
      redirect: "/web",

    },
    {

      path: "/web",
      name: "web",
      component: () => import("../views/home/homePage.vue"),
      meta: { title: "首页" },

    },
    // {

    //   path: "/introduction",
    //   name: "introduction",
    //   component: () => import("../views/introduction/introductionPage.vue"),
    //   meta: { title: "公司简介" },

    // },
    {
      path: "/mobile",
      name: "mobile",
      component: () => import("../views/mobilePage/mobilePage.vue"),
    },

  ],
});
//全局路由守卫----初始化的时候被调用，每次路由切换之前被调用
// router.beforeEach((to, from, next) => {

//   let detectDeviceInfo = detectDeviceType()
//   console.log(detectDeviceInfo, 'detectDeviceInfo')

//   if (detectDeviceInfo.includes("Desktop")) {
//     console.log('桌面')
//     next();
//   } else if (detectDeviceInfo.includes("Android") || detectDeviceInfo.includes("iOS")) {
//     console.log('手机端',)
//     next("/appPage");
//   }
// });
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

//5.导入路由实例
export default router;
