<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  // mounted() {
  //   this.aaa()
  // },
  // methods: {
  //   aaa() {

  //     localStorage.getItem('token')
  //     localStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODgwNzY2NDksImRhdGEiOnsidWlkIjoxMjksImNpZCI6MCwiY3RrIjoid1d0Nml3Y0MzZUdYYlkvcWZzMGlyQT09IiwiZWlkIjoid1d0Nml3Y0MzZUdYYlkvcWZzMGlyQT09IiwibmlkIjowfX0.uRfCVCh84t5KaLHqR7JrF')
  //   }
  // }
};
</script>

<style>
html body .el-table td .cell,
html body .el-table th .cell,
html body[class*="vab-theme-"] .el-table td .cell,
html body[class*="vab-theme-"] .el-table th .cell {
  font-size: 12px !important;
  font-weight: normal;
  color: #606266;
}
.user-management-container {
  padding: 20px 15px;
}
</style>
